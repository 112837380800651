import { useEffect } from "react";

import logoBlack from "./logo_black.png";

//css
import "./App.css";

function App() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "seresjanos_oktato";
    window.location.assign("https://www.tiktok.com/@tukorindexholtter");
    //
  }, []);

  return (
    <div className="App">
      <div className="header">seresjanos_oktato</div>
      <div className="logo-container">
        <img src={logoBlack} alt="" />
      </div>
    </div>
  );
}

export default App;
